function printf (str, params) {
  str = typeof str === 'string' ? str : ''
  if (!Array.isArray(params)) {
    params = Array.prototype.slice.call(arguments, 1)
  }
  let index = 0
  return str
    .replace(
      /%(([0-9]+)\$)?[A-Za-z]/g,
      function (match, pos, n) {
        if (pos) {
          index = n - 1
        }
        const val = params[index++]
        return typeof val !== 'undefined' ? val : 'unknown'
      }
    )
    .replace(/%%/g, '%')
}

function parsePluralHeader (header = 'plural=(n!=1);') {
  const [plural] = header.match(/plural=([^;]+)/) || []
  return {
    plural
  }
}

export class Dictionary {
  constructor (namespace, { headers, dict }) {
    this.namespace = namespace
    this.dict = dict
    this.headers = headers
  }

  set headers (headers = {}) {
    this._headers = headers
    const { plural } = parsePluralHeader(this.headers['Plural-Forms'])
    // eslint-disable-next-line no-new-func
    this.plural = new Function('n', 'return Number(' + plural + ');')
  }

  get headers () {
    return this._headers
  }

  async changeLanguage (language) {
    if (language === this.headers.Language) return this

    // prevent rollup dynamic import vars plugin from detecting this import
    // this is only needed in case the plugin is in use, but doesn't hurt
    let path
    try {
      path = new URL(`${this.namespace}.${language}.js`, window.location.href).href
    } catch (err) {
      path = `./${this.namespace.replace(/.*\//, '')}.${language}.js`
    }
    const { default: dict, headers } = await import(/* @vite-ignore */path)
    if (headers.Language !== language) headers.Language = language
    this.dict = dict
    this.headers = headers
  }

  gettext (string, ...args) {
    return this.npgettext('', string, '', 1, ...args)
  }

  pgettext (c, s, ...args) {
    return this.npgettext(c, s, '', 1, ...args)
  }

  ngettext (s, p, n, ...args) {
    return this.npgettext('', s, p, n, ...args)
  }

  npgettext (context, singular, plural, count, ...args) {
    const translations = this.dict[`${context}\x00${singular}\x01${plural}`] || []
    if (translations.length === 0) {
      translations.push(singular)
      translations.push(plural)
    }
    const translation = translations[this.plural(Number(count))] || translations[0]
    return args && args.length ? printf(translation, args) : translation
  }

  noI18n (a) {
    return a
  }
}


import dict, { headers } from './io.ox.blackwhitelist.en_US.js'
headers.Language = 'en_US'
export const dictionary = new Dictionary('io.ox.blackwhitelist', { headers, dict})

export const gt = new Proxy(
  function simpleGt (str, ...args) { return dictionary.npgettext('', str, '', 1, ...args) },
  {
    get (target, prop) {
      if (typeof dictionary[prop] === 'function') return dictionary[prop].bind(dictionary)
      return dictionary[prop]
    }
  }
)

export default gt