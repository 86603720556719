export var headers = {
	"Project-Id-Version": "open-xchange-appsuite-blackwhitelist",
	"Report-Msgid-Bugs-To": "",
	"POT-Creation-Date": "2021-11-02 11:24+0100",
	"PO-Revision-Date": "DATE",
	"Last-Translator": "NAME <EMAIL>",
	Language: "",
	"Language-Team": "NAME <EMAIL>",
	"Content-Type": "text/plain; charset=UTF-8",
	"Content-Transfer-Encoding": "8bit",
	"Plural-Forms": "nplurals=2; plural=(n != 1);",
	"MIME-Version": "1.0"
};
export default {
	headers: headers
};
